import {
    Divider, Menu as AMenu, Row, Button, Col, Space,
} from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useState } from 'react';
import LogoPNG from '../assets/images/logo-long.png';
import LogoNoTextPNG from '../assets/images/logo-no-text.png';

import '../styles/menu.less';
import { config } from '../constants/config';
import { LanguagesDropDown } from './header';
import UserIcon from '../assets/icons/user';
import MapMarkerIcon from '../assets/icons/marker';
import BoxIcon from '../assets/icons/box';
import { useUser } from '../hooks/useUser';

interface IMenuProps {
    collapsed?: boolean;
    drawer?: boolean;
}

function Menu({ collapsed, drawer }: IMenuProps) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { info } = useUser();
    // const orderTypes = useOrderTypes();
    // const [orderTypesOpen, setOrderTypesOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);

    const onItemClick = (key: string) => {
        navigate(`/${key}`);
    };

    const activeKey = pathname.replace('/', '') || 'profile';

    // const getOrderTypesChildren = (): ItemType[]|undefined => (orderTypes.data.length > 0 ? orderTypes.data.map((ot) => ({
    //     key: `make-order/${ot.value}`,
    //     label: ot.label,
    // })) : undefined);

    const menuItems: ItemType[] = [
        {
            label: t('profile'),
            key: 'profile',
            icon: <UserIcon />,
            children: [{
                key: 'orders',
                label: t('orders'),
            }, {
                key: 'profile',
                label: `${info?.name} ${info?.surname}`,
            }],
        },
        {
            label: t('warehouseAddresses'),
            key: 'my-addresses',
            icon: <MapMarkerIcon />,
        },
        {
            label: t('parcels'),
            key: 'parcels',
            icon: <BoxIcon />,
        },
        // {
        //     label: t('myPurchases'),
        //     key: 'purchases',
        //     icon: <BagIcon />,
        // },
        // {
        //     label: t('makeOrder'),
        //     key: 'make-order',
        //     icon: orderTypes.loading ? <Spin size="small" /> : <ListIcon />,
        //     children: getOrderTypesChildren(),
        // },
    ];

    if (drawer) {
        return (
            <div className="mobile-menu">
                <Row>
                    <Col xs={24}>
                        <Space className="w-100" direction="vertical">
                            <Button className="mobile-menu-item" icon={<UserIcon />} onClick={() => setProfileOpen((prev) => !prev)}>
                                {t('profile')}
                                {profileOpen ? <DownOutlined /> : <RightOutlined />}
                            </Button>
                            {profileOpen && (
                                <>
                                    <Link to="/orders">
                                        <Button className="mobile-menu-sub-item" type="link">
                                            {t('orders')}
                                        </Button>
                                    </Link>
                                    <Link to="/profile">
                                        <Button className="mobile-menu-sub-item" type="link">
                                            {`${info?.name} ${info?.surname}`}
                                        </Button>
                                    </Link>
                                </>
                            )}
                        </Space>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                    <Col xs={24}>
                        <Link to="/my-addresses">
                            <Button className="mobile-menu-item" icon={<MapMarkerIcon />}>
                                {t('warehouseAddresses')}
                                <RightOutlined />
                            </Button>
                        </Link>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                    <Col xs={24}>
                        <Link to="/parcels">
                            <Button className="mobile-menu-item" icon={<BoxIcon />}>
                                {t('parcels')}
                                <RightOutlined />
                            </Button>
                        </Link>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                    {/* <Col xs={24}>
                        <Link to="/purchases">
                            <Button className="mobile-menu-item" icon={<BagIcon />}>
                                {t('myPurchases')}
                                <RightOutlined />
                            </Button>
                        </Link>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col> */}
                    {/* <Col xs={24}>
                        <Space className="w-100" direction="vertical">
                            <Button className="mobile-menu-item" icon={<ListIcon />} onClick={() => setOrderTypesOpen((prev) => !prev)}>
                                {t('makeOrder')}
                                {orderTypesOpen ? <DownOutlined /> : <RightOutlined />}
                            </Button>
                            {orderTypesOpen && orderTypes.data.length > 0 && orderTypes.data.map((ot) => (
                                <Link to={`make-order/${ot.value}`}>
                                    <Button className="mobile-menu-sub-item" type="link">
                                        {ot.label}
                                    </Button>
                                </Link>
                            ))}
                        </Space>
                    </Col> */}
                    <Col xs={24}>
                        <Space className="mobile-menu-bottom-actions">
                            <LanguagesDropDown />
                            <Link to="/profile">
                                <Button type="link" icon={<UserIcon height={32} width={32} style={{ fill: '#db8912' }} />} />
                            </Link>
                        </Space>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <>
            <div className="menu-logo-container">
                <a href={config.siteUrl}>
                    <img alt="Telega" className="menu-logo" src={collapsed ? LogoNoTextPNG : LogoPNG} />
                </a>
            </div>
            <Divider />
            <AMenu
                selectedKeys={[activeKey]}
                items={menuItems}
                mode="inline"
                onClick={({ key }) => onItemClick(key)}
            />
        </>
    );
}

Menu.defaultProps = {
    collapsed: false,
};

export default Menu;

import React, { useEffect, useState } from 'react';
import {
    Drawer, Grid, Layout,
} from 'antd';
import { useLocation } from 'react-router-dom';
import Header from '../components/header';
import Menu from '../components/menu';

import '../styles/authed-layout.less';

const { useBreakpoint } = Grid;

const {
    Content, Sider,
} = Layout;

function AuthedLayout({ children }: React.PropsWithChildren) {
    const { lg } = useBreakpoint();
    const [collapsed, setCollapsed] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const location = useLocation();

    const onDrawerClose = () => setDrawer(false);

    const onMenuClick = () => setDrawer((value) => !value);

    useEffect(() => {
        if (lg) {
            setDrawer(false);
        }
    }, [lg]);

    useEffect(() => {
        onDrawerClose();
    }, [location.pathname]);

    return (
        <Layout>
            <Sider
                hidden={!lg}
                breakpoint="lg"
                collapsible
                collapsedWidth={!lg ? 0 : undefined}
                theme="light"
                onCollapse={setCollapsed}
                width={280}
            >
                <Menu collapsed={collapsed} />
            </Sider>
            <Drawer
                className="mobile-menu-drawer"
                placement="top"
                open={!lg && drawer}
                onClose={onDrawerClose}
                width={250}
                bodyStyle={{ padding: 0 }}
                closable={false}
            >
                <Menu drawer />
            </Drawer>
            <Layout>
                <Header drawerOpen={drawer} showMenuBtn={!lg} onMenuClick={onMenuClick} />
                <Content className="authed-layout-content">
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
}

export default AuthedLayout;
